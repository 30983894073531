export default {
  data() {
    return {
      table: {
        debounceTimer: null,
        loading: false,
        pageOptions: [10, 25, 50, 100],
        page: 1,
        currentPage: 1,
        perPage: 10,
        total: 0,
        from: 0,
        to: 0,
        search: '',
        name: '',
        totalBag: 0,
        totalReagen: 0,
        items: [],
      },
    }
  },
  computed: {
    itemsFormatted() {
      return this.table.items.map((item, index) => ({
        no: this.calculateRowNumber(index),
        ...item,
      }))
    },
  },
  methods: {
    calculateRowNumber(index) {
      // Calculate the row number based on the current page and number of items per page

      /*
     * MATH BEHIND IT
     *
     * For the first item on page 1:
        (1 - 1) * 10 + 0 + 1 = 0 * 10 + 0 + 1 = 1
        For the last item on page 1:
        (1 - 1) * 10 + 9 + 1 = 0 * 10 + 9 + 1 = 10
        For the first item on page 2:
        (2 - 1) * 10 + 0 + 1 = 1 * 10 + 0 + 1 = 11
        For the last item on page 2:
        (2 - 1) * 10 + 9 + 1 = 1 * 10 + 9 + 1 = 20
     *
     * */

      return (this.table.currentPage - 1) * this.table.perPage + index + 1
    },
    transformColumns(columns = []) {
      this.table.fields = columns
      this.table.filtered = columns
        .filter(col => col.filtered)
        .map(col => col.key)
    },
    getTableNumber(index) {
      const indexed = (this.table.currentPage - 1) * this.table.perPage
      return index + indexed + 1
    },
    onChangeSearchTable(search) {
      this.table.search = search
    },
    onFilteredTable(filteredItems) {
      this.table.total = filteredItems.length
      this.table.totalReagen = filteredItems.length
      this.table.totalBag = filteredItems.length
      this.table.currentPage = 1
    },
  },
}
