<template>
  <div>
    <div class="bg-white p-3 rounded shadow-sm">
      <h1>Prolis</h1>
      <section>
        <b-form>
          <b-row class="align-items-center">
            <b-col>
              <b-form-group label="Kode Kantong:" label-for="kodeKantong">
                <div class="position-relative">
                  <b-form-input
                    id="kodeKantong"
                    v-model="barcodeInput"
                    class="pr-5"
                    placeholder="Masukkan Kode Kantong"
                    type="text"
                    @input="checkBarcodes"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="link"
                      class="p-0 btn-clear"
                      @click="clearInput"
                    >
                      <i
                        class="ri-close-circle-fill"
                        style="font-size: 1.5rem; color: #dc3545"
                      />
                    </b-button>
                  </b-input-group-append>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="isLoading" class="hp-p1-body text-center my-2">
              <b-spinner class="align-middle" />
              <strong>Memuat...</strong>
            </b-col>
          </b-row>
        </b-form>
      </section>

      <div
        v-if="isShowData"
        class="border border-gray-500 rounded-lg border-2 p-5"
      >
        <section id="KGD" class="text-center mb-5">
          <h3>KGD</h3>
          <table class="table table-sm">
            <tr>
              <th>Gol Darah Sebelum</th>
              <th>Gol Darah Hasil Konfirmasi</th>
            </tr>
            <tr>
              <td>{{ prolis.kgd?.initial_blood }}</td>
              <td>{{ prolis.kgd?.result_blood }}</td>
            </tr>
          </table>
        </section>

        <section id="antibody" class="text-center mb-5">
          <h3>Hasil Anti Body</h3>
          <div v-if="prolis.antibody">
            <b-badge
              size="lg"
              :variant="
                prolis.antibody.status === 'negative' ? 'primary' : 'danger'
              "
            >
              {{
                prolis.antibody.status === "negative"
                  ? "Negatif"
                  : prolis.antibody.status
              }}
            </b-badge>
          </div>
        </section>

        <section id="screening" class="text-center mb-5">
          <h3>Hasil IMLTD</h3>
          <b-badge
            class="mb-3"
            size="lg"
            :variant="
              prolis.screening?.status === 'non-reaktif'
                ? 'success'
                : prolis.screening?.status === 'reaktif'
                ? 'danger'
                : prolis.screening?.status === 'greyzone'
                ? 'light'
                : null
            "
          >
            {{
              prolis.screening?.status === "non-reaktif"
                ? "Non-Reaktif"
                : prolis.screening?.status === "reaktif"
                ? "Reaktif"
                : prolis.screening?.status === "greyzone"
                ? "Grey Zone"
                : null
            }}
          </b-badge>
          <table class="table table-sm">
            <tr>
              <th>Syphillis</th>
              <th>Hepatitis B</th>
              <th>Hepatitis C</th>
              <th>HIV</th>
            </tr>
            <tr>
              <td>
                {{
                  prolis.screening?.status === "non-reaktif" &&
                  prolis.screening?.syphilis === null
                    ? "Neg"
                    : prolis.screening?.syphilis
                    ? prolis.screening?.syphilis
                    : "-"
                }}
              </td>
              <td>
                {{
                  prolis.screening?.status === "non-reaktif" &&
                  prolis.screening?.hepatitis_b === null
                    ? "Neg"
                    : prolis.screening?.hepatitis_b
                    ? prolis.screening?.hepatitis_b
                    : "-"
                }}
              </td>
              <td>
                {{
                  prolis.screening?.status === "non-reaktif" &&
                  prolis.screening?.hepatitis_c === null
                    ? "Neg"
                    : prolis.screening?.hepatitis_c
                    ? prolis.screening?.hepatitis_c
                    : "-"
                }}
              </td>
              <td>
                {{
                  prolis.screening?.status === "non-reaktif" &&
                  prolis.screening?.hiv_aids === null
                    ? "Neg"
                    : prolis.screening?.hiv_aids
                    ? prolis.screening?.hiv_aids
                    : "-"
                }}
              </td>
            </tr>
          </table>
        </section>

        <section id="form">
          <h4>
            Donor
            <span class="text-capitalize">{{
              detailInformation.donor_type
            }}</span>
          </h4>
          <div class="w-100 table-wrapper text-center overflow-scroll mx-auto">
            <b-table
              responsive
              style="white-space: nowrap"
              :fields="fields"
              :items="table.items"
            />
          </div>
          <div class="d-flex justify-content-end">
            <base-button
              variant="primary"
              text="Simpan"
              :disabled-mode="
                prolis.screening?.status === 'reaktif' ||
                prolis.screening?.status === 'greyzone'
              "
              :is-busy="isBtnLoading"
              @click="postCollectedProlis"
            />
          </div>
        </section>
      </div>
    </div>
    <div>
      <div class="bg-white p-3 rounded shadow-sm mt-5">
        <h2>Daftar Prolis</h2>
        <b-input-group class="w-50 float-right">
          <b-input-group-prepend>
            <b-button
              variant="outline-black-50"
              class="border-right-0 pr-0 pl-2"
            >
              <i class="ri-search-line remix-icon text-primary" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="listProlis.search"
            type="search"
            placeholder="Search"
            class="border-left-0"
            @input="debounceSearch"
          />
        </b-input-group>
        <p class="mb-0">Semua ({{ listProlis.total }})</p>

        <b-form-group
          label="Filter Berdasarkan Komponen"
          label-for="filter-component"
          class="mb-0 w-25"
        >
          <b-form-select
            id="filter-component"
            v-model="filterByComponent"
            :options="optionsComps"
            @input="handleCompsInput"
          />
        </b-form-group>

        <b-table
          responsive
          :items="listProlis.items"
          :fields="listProlis.fields"
          :busy="listProlis.loading"
          :per-page="table.perPage"
          :current-page="table.currentPage"
          show-empty
          class="mt-3"
        >
          <template #table-busy>
            <div class="hp-p1-body text-center my-2">
              <b-spinner class="align-middle mr-8" />
              <strong>Memuat...</strong>
            </div>
          </template>
          <template #empty>
            <p class="hp-p1-body text-center">Tidak Ada Data</p>
          </template>
          <template #cell(action)="data">
            <base-button
              style="white-space: nowrap"
              text="Lihat & Cetak Label"
              variant="info"
              @click="goToPrintPage(data.item)"
            />
          </template>
        </b-table>
        <b-pagination
          v-model="listProlis.currentPage"
          class="mb-3"
          align="right"
          :per-page="listProlis.perPage"
          :total-rows="listProlis.total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BRow,
  BBadge,
  BSpinner,
  BTable,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import { debounce } from "lodash";
import productReleaseAPI from "../../../../api/product_releases/productReleaseAPI";
import tableDataMixins from "../../../../mixins/tableDataMixins";

export default {
  components: {
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BBadge,
    BSpinner,
    BTable,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BFormSelect,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      isShowData: false,
      isBtnLoading: false,
      isLoading: false,
      isBusy: false,
      filterByComponent: "",
      barcodeInput: "",
      debouncedBarcode: null,
      detailInformation: {},
      optionsComps: [
        { value: "", text: "Pilih Berdasarkan Komponen" },
        { value: "PRC", text: "PRC" },
        { value: "LP", text: "LP" },
        { value: "TC", text: "TC" },
        { value: "FFP", text: "FFP" },
      ],
      fields: [
        {
          key: "component_barcode",
          label: "Barcode Hasil Olah",
        },
        {
          key: "blood_bag_number",
          label: "No. Selang",
        },
        {
          key: "volume",
          label: "Volume",
        },
        {
          key: "aftap_date",
          label: "Tgl. Aftap",
        },
        {
          key: "expired_date",
          label: "Tgl. Kedaluarsa",
        },
      ],
      prolis: {
        kgd: null,
        sceening: null,
        antibody: null,
      },
      listProlis: {
        debounceTimer: null,
        loading: false,
        searchQuery: "",
        isBusy: false,
        items: [],
        fields: [
          {
            key: "component_barcode",
            label: "Barcode",
          },
          {
            key: "blood_bag_number",
            label: "Nomor Selang",
          },
          {
            key: "blood_type_result",
            label: "Hasil Golongan Darah",
          },
          {
            key: "volume",
            label: "Volume",
          },
          {
            key: "prod_date",
            label: "Tgl. Produksi",
          },
          {
            key: "expired_date",
            label: "Tgl. Kedaluwarsa",
          },
          {
            key: "action",
            label: "Aksi",
          },
        ],
        total: 0,
        currentPage: 1,
        perPage: 10,
        search: "",
      },
    };
  },
  created() {
    this.fetchData();

    this.debouncedBarcode = debounce(this.checkBarcodes, 300);
  },
  methods: {
    async checkBarcodes() {
      if (!this.barcodeInput.trim()) {
        this.resetData();
        return;
      }

      this.isBusy = true;
      this.isLoading = true;
      this.isShowData = false;

      try {
        const response = await productReleaseAPI.checkingBarcode(
          this.barcodeInput
        );

        if (response && response.data.success) {
          this.isShowData = true;

          const { data } = response.data;

          this.prolis.kgd = data.kgd || null;
          this.prolis.antibody = data.screening_antibodi || null;
          this.prolis.screening = data.screening || null;

          this.detailInformation = {
            donor_type: data?.donor_type,
            component: data?.component,
            component_barcode: data?.component_barcode,
            blood_bag_number: data?.blood_bag_number,
            aftap_date: data?.aftap_date,
            expired_date: data?.expired_date,
            prod_date: data?.prod_date,
            volume: data?.volume,
            antibody: data?.screening_antibodi.status,
          };

          this.table.items = [this.detailInformation];
          this.isShowData = true;
        }
      } catch (e) {
        this.$bvToast.toast(e.response.data.message, {
          title: "Terjadi Kesalahan",
          variant: "danger",
          solid: true,
        });

        this.isLoading = false;
      } finally {
        this.isBusy = false;
        this.isLoading = false;
      }
    },
    async postCollectedProlis() {
      this.isBtnLoading = true;

      try {
        const collectedComponents = {
          donor_type: this.detailInformation.donor_type,
          component_barcode: this.detailInformation.component_barcode,
          component: this.detailInformation.component,
          blood_bag_number: this.detailInformation.blood_bag_number,
          volume: this.detailInformation.volume,
          aftap_date: this.detailInformation.aftap_date,
          prod_date: this.detailInformation.prod_date,
          expired_date: this.detailInformation.expired_date,
          blood_type_result: this.prolis.kgd.result_blood,
        };

        const response = await productReleaseAPI.add(collectedComponents);

        this.$bvToast.toast(response.data.message, {
          title: "Data Berhasil Dikirim",
          variant: "success",
          solid: true,
        });

        await this.clearInput();
        await this.fetchData();
      } catch (e) {
        this.$bvToast.toast(e.response.data.message, {
          title: "Terjadi Kesalahan",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isBtnLoading = false;
      }
    },
    async fetchData() {
      this.listProlis.loading = true;

      try {
        const params = {
          page: this.listProlis.currentPage,
          per_page: this.listProlis.perPage,
          search: this.listProlis.search,
          component: this.filterByComponent
            ? this.filterByComponent
            : undefined,
        };

        const { data: response } = await productReleaseAPI.listProlis({
          params,
        });

        this.listProlis.items = response.data.data;
        this.listProlis.total = response.data.total;
      } catch (e) {
        console.error(e);
        this.listProlis.loading = false;
      } finally {
        this.listProlis.loading = false;
      }
    },
    resetData() {
      this.isShowData = false;
      this.isLoading = false;
      this.isBusy = false;
      this.prolis = {
        kgd: null,
        screening: null,
        antibody: null,
      };
      this.detailInformation = {};
      this.table.items = [];
    },
    clearInput() {
      this.barcodeInput = "";
      this.resetData();
    },
    // fungsi untuk paginations dan search
    handleCompsInput(selectedValue) {
      this.filterByComponent = selectedValue;

      this.fetchData();
    },
    changePage(page) {
      this.listProlis.currentPage = page;
      this.fetchData();
    },
    changePage(page) {
      this.listProlis.currentPage = page;
      this.fetchData();
    },
    debounceSearch() {
      clearTimeout(this.listProlis.debounceTimer);
      this.listProlis.searchQuery = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      this.fetchData();
    },
    goToPrintPage(item) {
      this.$router.push({
        name: "print-listproductions",
        params: { barcode: item.barcode },
        query: {
          donor_type: item?.donor_type,
          component: item?.component,
          component_barcode: item?.component_barcode,
          blood_bag_number: item?.blood_bag_number,
          aftap_date: item?.aftap_date,
          expired_date: item?.expired_date,
          prod_date: item?.prod_date,
          volume: item?.volume,
          blood_type_result: item?.blood_type_result,
          antibody: item?.antibody,
        },
      });

      console.log("Item Tampil: ", item);
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.table-wrapper table {
  width: 100%;
  table-layout: fixed;
}

.table-wrapper th,
.table-wrapper td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-scroll {
  overflow-y: auto;
}

.position-relative {
  position: relative;
}

.btn-clear {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-clear i {
  font-size: 1.25rem;
}
</style>
